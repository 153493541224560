import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { showNotice, showMessage, showConfirm } from 'components';
import { fetchFile } from '../utils';


class GetFile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    if (this.props.user == null) {
      return;
    }

    this.getFile(this.props.params.file);
  }

  
  getFile(file) {
    file = file.replaceAll('|', '/');
    const fileName = file.substring(file.lastIndexOf('/'), file.length);

    fetchFile(null, 'GET', file).then(data => {
      this.downloadFile(data, fileName);
    }).catch(error => {
      this.props.showNotice('Tiedostoa ei löytynyt', 'Warning');
      this.setState({
        redirect: true,
      });
    }).finally(() => {
      this.setState({ loading: false });
    });
  }

  downloadFile(blob, name) {
    const blobUrl = URL.createObjectURL(blob);
  
    const link = document.createElement("a");
  
    link.href = blobUrl;
    link.download = name;
  
    document.body.appendChild(link);
  
    link.dispatchEvent(
      new MouseEvent('click', { 
        bubbles: true, 
        cancelable: true, 
        view: window 
      })
    );
  
    document.body.removeChild(link);
  }

  render() {
    if (this.state.loading) {
      return (
        <div className='main-client-container'>
          <div className='loader'></div>
        </div>
      );
    }

    return <Navigate to={'/'} push/>;
  }
}

export default connect(state => ({
  user: state.login.get('user'),
}), { showNotice, showMessage, showConfirm })(GetFile);
