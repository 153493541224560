import React from "react";
import { showMessage, showNotice } from "components";
import { fetch, getPropertyFromJson, currentTimeWithZ } from "../utils";
import Form from "@rjsf/core";
import { connect } from "react-redux";
import './Report.css';


class SignReport extends React.Component {

  constructor(props) {
    super(props);

    this.getReport(parseInt(this.props.params.id, 10));

    this.state = {
      schema: null,
      formData: {},
      uiSchema: null,
      report: null,
      signSheet: this.props.params.sheet,
      signField: this.props.params.field,
      token: this.props.location.search.split("token=")[1]
    }
  }

  componentWillUpdate (nextProps, nextState) {
    if (this.state.report !== nextState.report) {
      if (nextState.report.sheets) {
        if (nextState.report.sheets.hasOwnProperty(this.state.signSheet) &&
          nextState.report.sheets[this.state.signSheet].hasOwnProperty(this.state.signField) &&
          this.state.token != null) {
          this.setForm(nextState.report, this.state.signSheet, this.state.signField)
        } else {
          this.props.showNotice('Raportti ei löytynyt', 'Error');
        }
      }
    }
  }

  getReport (id) {
    fetch('/report/' + id).then(data => {
      this.setState({
        report: data
      });
    }).catch(error => {
      this.props.showNotice('Raportti ei löytynyt', 'Warning');
      this.setState({
        redirect: true,
      });
    });
  }

  setForm (signingReport, signingSheet, signingField) {

    let uiSchema = { "ui:disabled": true };

    let formData = {
      contract: signingReport.constructionSite.siteContract.name,
      constructionSite: signingReport.constructionSite.name,
    };

    let schema = {
      type: "object",
      required: ["contract", "constructionSite"],
      properties: {
        contract: { type: "string" },
        constructionSite: { type: "string" },
      }
    };

    Object.keys(signingReport.sheets).forEach(sheet => {
      let sheet_name = function (sheet) {
        if (sheet.indexOf("_") > -1) {
          let name = sheet.split("_")[1];
          if (name === "blank")
            return "";
          else
            return name
        } else {
          return "";
        }
      };

      if (sheet.indexOf("Object") === 0) { // skip custom object sheet
        return;
      } else if (sheet.indexOf("single") > -1) { // add sheet which only has one row
        let tableSchema = { title: sheet_name(sheet), type: "object", properties: {} };
        let tableData = {};
        let tableUi = {};
        Object.keys(signingReport.sheets[sheet]).forEach(field => {

          let prop = getPropertyFromJson(signingReport, sheet, field, true);
          tableSchema.properties[field] = prop.schema;
          if (prop.ui && Object.keys(prop.ui).length > 0)
            tableUi[field] = prop.ui;
          if (prop.data) {
            if (Array.isArray(prop.data))
              tableData[field] = prop.data[0];
            else
              tableData[field] = prop.data;
          }

          // add sign button for signing field
          if (sheet === signingSheet && field === signingField) {

          }

        });
        schema.properties[sheet] = tableSchema;
        formData[sheet] = tableData;
        uiSchema[sheet] = tableUi;
      } else {
        // add sheet which has multiple rows
        let tableSchema = {
          title: sheet_name(sheet),
          type: "array",
          items: {
            type: "object",
            properties: {}
          }
        };
        let tableData = [{}];
        let tableUi = {
          "ui:options": {
            orderable: false
          },
          "items": {}
        };

        Object.keys(signingReport.sheets[sheet]).forEach(field => {
          let prop = getPropertyFromJson(signingReport, sheet, field, true);
          tableSchema.items.properties[field] = prop.schema;
          if (prop.ui && Object.keys(prop.ui).length > 0)
            tableUi["items"][field] = prop.ui;
          if (prop.data) {
            prop.data.forEach((value, index) => {
              if (tableData.length - 1 < index) {
                tableData.push({});
              }
              tableData[index][field] = value;

            });
          }
        });
        schema.properties[sheet] = tableSchema;
        formData[sheet] = tableData;
        uiSchema[sheet] = tableUi;
      }
    });

    this.setState({
      formData: formData,
      schema: schema,
      uiSchema: uiSchema
    });
  }

  onSubmit () {
    let signInfo = {
      "sheet_name": this.state.signSheet,
      "field_name": this.state.signField,
      "sign_time": currentTimeWithZ(),
      "email": this.state.report.sheets[this.state.signSheet][this.state.signField].value[0]
    };

    fetch('/report/' + this.state.report.id + '/sign',
      'POST', signInfo, this.state.token.replace("Bearer%20", 'Bearer ')).then((data) => {
        if (data) {
          this.setState({
            report: data
          });
        }
        this.props.showMessage('you signed successfully', 'OK');
      }).catch(error => {
        console.log(error);
        this.props.showNotice('Kohteen muokkaaminen epäonnistui', 'Error');
      });
  }

  render () {
    if (this.state.schema == null) {
      return (
        <div className="main-client-container">
          <h2>Ladataan tietoja...</h2>
          <div className="loader"></div>
        </div>
      );
    }
    return (
      <div className="main-client-container">
        <h1>Muokkaa raportti</h1>
        <h2>{this.state.report.template.title}</h2>
        <h3>{this.state.report.template.name}</h3>
        <h4>last modify time: {this.state.report.last_modify.replace('T', ' ')}</h4>
        <Form schema={this.state.schema} uiSchema={this.state.uiSchema}
          onSubmit={this.onSubmit.bind(this)} formData={this.state.formData}
          showErrorList={false}>
          <div>
            <button className="button-primary" type="submit">Sign as {this.state.signField}</button>
          </div>
        </Form>
      </div>
    );
  }
}

export default connect(state => ({
}), { showNotice, showMessage })(SignReport);