import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Confirm, login, Menu, Message, Notice, Watermark } from 'components';
import config from '../../settings/config';


class App extends React.Component {

  constructor(props) {
    super(props);

    localStorage.loadingSuccess = true;

    this.path = this.props.location.pathname;
    this.login = false;
  }

  componentDidUpdate (oldProps, oldState) {
    if (this.props.user == null && oldProps.user !== this.props.user) {
      window.location.reload();
    }
  }

  render () {
    if (this.props.user == null) {
      if (!this.login) {
        this.login = true;

        return <Navigate to='/login' />;
      }
    }
    else {
      if (this.props.location.pathname.match('/signreport/[1-9]d*/.*/.*')) {
        return <Navigate to={this.props.location.pathname + this.props.location.search} />
      }

      if (this.props.location.pathname !== '/' &&
        (this.props.user.get('orderer') ||
          (!this.props.location.pathname.includes('/users') &&
            !this.props.location.pathname.includes('/users/new') &&
            !this.props.location.pathname.match('/user/[1-9]d*') &&
            !this.props.location.pathname.includes('/organizations') &&
            !this.props.location.pathname.includes('/organizations/new') &&
            !this.props.location.pathname.match('/organization/[1-9]d*') &&
            !this.props.location.pathname.includes('/trainings') &&
            !this.props.location.pathname.includes('/training/new') &&
            !this.props.location.pathname.match('/training/[1-9]d*') &&
            !this.props.location.pathname.includes('/contracts') &&
            !this.props.location.pathname.includes('/contracts/new') &&
            !this.props.location.pathname.match('/contract/[1-9]d*') &&
            !this.props.location.pathname.includes('/sites') &&
            !this.props.location.pathname.includes('/sites/new') &&
            !this.props.location.pathname.match('/site/[1-9]d*') &&
            !this.props.location.pathname.includes('/trucks') &&
            !this.props.location.pathname.includes('/trucks/new') &&
            !this.props.location.pathname.match('/truck/[1-9]d*') &&
            !this.props.location.pathname.includes('/report') &&
            !this.props.location.pathname.includes('/reports') &&
            !this.props.location.pathname.includes('/reports/new') &&
            !this.props.location.pathname.match('/report/[1-9]d*') &&
            !this.props.location.pathname.match('/signreport/[1-9]d*/.*/.*') &&
            !this.props.location.pathname.includes('/templates') &&
            !this.props.location.pathname.includes('/templates/new') &&
            !this.props.location.pathname.match('/template/[1-9]d*') &&
            !this.props.location.pathname.includes('/logout') &&
            !this.props.location.pathname.includes('/file')))) {
        if (this.path !== this.props.location.pathname) {
          return <Navigate to={this.path} />;
        }

        return <Navigate to='/' />;
      }
    }
    return (
      <div>
        {this.props.user != null ? <Menu store={this.props.store} /> : null}
        <Message store={this.props.store} />
        <Notice store={this.props.store} />
        <Confirm store={this.props.store} />
        {config.reptail ? <Watermark /> : null}
      </div>
    );
  }
}


export default connect(state => ({
  user: state.login.get('user'),
}), { login })(App);
