import React from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { showNotice, showMessage, showConfirm } from 'components';
import { fetch } from '../utils';
import Form from "@rjsf/core";


class OrganizationEdit extends React.Component {
  constructor(props) {
    super(props);

    this.getOrganization(parseInt(this.props.params.id, 10));

    this.state = {
      schema: null,
      uiSchema: {},
      formData: {},
      loading: false
    };

    this.onSubmit = this.onSubmit.bind(this)
    this.confirmOrganizationDelete = this.confirmOrganizationDelete.bind(this);
    this.deleteOrganization = this.deleteOrganization.bind(this);
  }

  componentDidUpdate (lastProps, lastState) {
    if (this.state.schema == null && this.state.editingOrganization != null) {
      this.setForm();
    }
  }

  setForm () {
    const schema = {
      type: "object",
      required: ["name"],
      properties: {
        name: { type: "string", title: "Sähköposti:" },
        companyCode: { type: "string", title: "Y-Tunnus:" },
        active: { type: "boolean", title: "Aktiivinen:" }
      }
    };

    const uiSchema = {
      active: {
        "ui:widget": "radio",
      }
    };

    const formData = {
      name: this.state.editingOrganization['name'],
      companyCode: this.state.editingOrganization['company_code'] || '',
      active: this.state.editingOrganization['active']
    };

    this.setState({
      schema: schema,
      uiSchema: uiSchema,
      formData: formData
    });
  }


  getOrganization (id) {
    fetch('/organizations/' + id).then(data => {
      this.setState({ editingOrganization: data });
    }).catch(error => {
      this.props.showNotice('Organisaatiota ei löytynyt', 'Warning');
      this.setState({ redirect: true });
    });
  }

  async onSubmit ({ formData }) {
    if (formData.password !== formData.passwordConfirm) {
      this.props.showNotice('Salasanat eivät täsmää', 'Warning');
      return;
    }

    this.setState({ loading: true });

    const organization = {
      id: this.state.editingOrganization.id,
      name: formData.name,
      company_code: formData.companyCode || null,
      active: formData.active
    };

    fetch('/organizations/' + organization.id, 'PATCH', organization).then(async data => {
      this.props.showNotice('Käyttäjän muokkaaminen onnistui', 'Ok');
      this.setState({ redirect: true });
    }).catch(error => {
      this.setState({ loading: false });
      this.props.showNotice('Käyttäjän muokkaaminen epäonnistui', 'Error');
    });
  }

  confirmOrganizationDelete (event) {
    event.preventDefault();
    this.props.showConfirm('Poistetaanko organisaatio?', this.deleteOrganization);
  }

  deleteOrganization () {
    fetch('/organizations/' + this.state.editingOrganization.id, 'DELETE').then(() => {
      this.props.showNotice('Poistettu', 'Ok')
      this.setState({ redirect: true });
    }).catch(error => {
      this.props.showMessage('Virhe', 'Poisto epäonnistui', 'Error');
    });
  }

  render () {
    if (this.state.redirect) return <Navigate to='/organizations' push />;

    if (this.state.schema == null) {
      return (
        <div className='main-client-container'>
          <h2>Ladataan tietoja...</h2>
          <div className='loader'></div>
        </div>
      );
    }

    return (
      <div className='main-client-container'>
        <h1>Muokkaa organisaatiota</h1>
        <h2>{this.state.editingOrganization['name']}</h2>
        <Form schema={this.state.schema} uiSchema={this.state.uiSchema}
          onSubmit={this.onSubmit} formData={this.state.formData}
          onChange={this.onChange} showErrorList={false}>
          {this.state.loading ?
            <div className='loader' />
            :
            <div>
              <input className='button-primary' type='submit' value='Tallenna' />
              <Link className='button-primary' to='/organizations'>
                <button className='button-outline'>Peruuta</button>
              </Link>
              <button className='float-right red-text button-outline'
                onClick={this.confirmOrganizationDelete}>
                Poista
              </button>
            </div>
          }
        </Form>
      </div>
    );
  }
}

export default connect(state => ({
  user: state.login.get('user'),
  contracts: state.contractSelect.get('contracts'),
}), { showNotice, showMessage, showConfirm })(OrganizationEdit);
