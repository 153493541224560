import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { showMessage, List } from 'components';
import './Contracts.css';


class Contracts extends React.Component {

  render () {
    console.log(this.props);
    return (
      <div>
        <div className="main-client-container">
          <h1>Urakat</h1>
          <div className='center'>
            <Link to="/contracts/new">
              <button type="button">Uusi urakka</button>
            </Link>
          </div>
          <List many={10}
            emptyText={'Ei urakoita'}
            header={['Nimi', 'Kausi', '']}
            fields={['name', 'year', 'button#Muokkaa#/contract/{id}']}
            data={this.props.contracts} />
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  contracts: state.contractSelect.get('contracts'),
}), { showMessage })(Contracts);
