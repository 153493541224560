import React from "react";
import { connect } from "react-redux";
import SignatureCanvas from 'react-signature-canvas';
import './Report.css';

class Signature extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            signing: false,
            trimmedDataUrl: this.props.dataUrl
        };
    }

    sigCanvas = {};

    clear = () => {
        this.sigCanvas.clear();
    };

    trim = () => {
        let data = this.sigCanvas.toDataURL("image/png");
        // update image data to Form
        this.props.parentOnChange(data);
        // update trimmed image and close this window
        this.setState({
            trimmedDataUrl: data,
            signing: false
        })
    };

    close = () => {
        this.setState({
            signing: false
        })
    };

    openSigning = () => {
        this.setState({
            signing: true
        });
    };

    render() {
        return(
            <div>
                <img width={240} height={96} src={this.state.trimmedDataUrl} className="inline" alt="click to add signature" onClick={this.openSigning}/>
                {/*<input type="button" onClick={this.openSigning} value="Sign Here" className="inline" />*/}
                {this.state.signing?
                    <div className='signing'>
                        <SignatureCanvas penColor='green'
                                         canvasProps={{width: 500, height: 200, className: 'sigCanvas'}}
                                         ref={(ref) => { this.sigCanvas = ref;}}/>
                        <div>
                            <input type='button' className='button-primary' onClick={this.clear} value='Clear'/>
                            <input type='button' className='button-primary' onClick={this.trim} value='Save'/>
                            <button onClick={this.close}>cancel</button>
                        </div>
                    </div>:null
                }
            </div>
        )
    }
}

export default connect(state => ({
}), {})(Signature);