import React from 'react';
import { connect } from 'react-redux';
import L from 'leaflet';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import { showNotice, MapTiles } from 'components';
import { toETRSTM35FIN } from '../utils.js';
import './CoatingPlantSelect.css'


const Selector = props => {
  return (
    <div>
      <label>{props.label}</label>
      {(props.name !== '' ? props.name + ' ' : '') + 'lat: ' + (props.latitude ? Math.round(props.latitude * 10000) / 10000 : '-') + ' lon: ' + 
        (props.longitude ? Math.round(props.longitude * 10000) / 10000 : '-')}
      <button id='coating-plant-select-button' className="button-outline" onClick={props.toggleSetting}>
        Aseta sijainti
      </button>
    </div>
  );
}

class CoatingPlantSelect extends React.Component {

  constructor(props){
    super(props);

    this.updateDimensions = this.updateDimensions.bind(this);
    this.toggleSetting = this.toggleSetting.bind(this);
    this.setCoatingPlant = this.setCoatingPlant.bind(this);
    this.acceptCoatingPlant = this.acceptCoatingPlant.bind(this);
    this.changeName = this.changeName.bind(this);

    this.position = [64.1, 26.5];
    this.zoom = 6;

    const height = window.innerHeight * 0.6;

    this.state = {
      setting: false,
      yourLaditude: null,
      yourLongitude: null,
      coatingPlantLaditude: null,
      coatingPlantLongitude: null,
      setCoatingPlantLaditude: null,
      setCoatingPlantLongitude: null,
      setName: '',
      mapTilesUrl: '',
      mapTilesAttribution: '',
      maxZoom: 16,
      height: height,
      title: 'Valitse aseman sijainti',
      label: (this.props.index + 1) + '. Asema',
      icon: 'coating_plant_map.gif',
      iconSize: [33, 50],
      name: ''
    };
  }

  componentDidMount() {
    if (this.props.macadam) {
      this.setState({
        title: 'Valitse montun sijainti',
        label: (this.props.index + 1) + '. Monttu',
        icon: 'pit_map.png',
        iconSize: [50, 18],
      });
    }

    window.addEventListener("resize", this.updateDimensions)

    if (!navigator.geolocation) {
      return;
    }

    this.watchID = navigator.geolocation.watchPosition(position => {
      this.setState({
        yourLaditude: position.coords.latitude,
        yourLongitude: position.coords.longitude
      });
    }, error => {
      return;
    }, {enableHighAccuracy: true});
  }

  componentWillUnmount() {
    navigator.geolocation.clearWatch(this.watchID);
    window.removeEventListener("resize", this.updateDimensions)
  }

  componentDidUpdate() {
    if (this.map == null) {
      return ;
    }
    
    const mapCenter = this.map.leafletElement.getCenter();
    const converted = toETRSTM35FIN(mapCenter.lat, mapCenter.lng)
    const mapTiles = MapTiles(converted.x, converted.y);

    if (this.state.mapTilesUrl !== mapTiles.url) {
      this.setState({
        mapTilesUrl: mapTiles.url,
        mapTilesAttribution: mapTiles.attribution,
        maxZoom: mapTiles.maxZoom
      });
    }
  }

  updateDimensions() {
    const height = window.innerHeight * 0.6;
    this.setState({ height: height })
  }

  toggleSetting() {
    this.setState({
      setting: !this.state.setting,
    });
  }

  setCoatingPlant(event) {
    this.setState({
      coatingPlantLaditude: event.latlng.lat,
      coatingPlantLongitude: event.latlng.lng
    });
  }

  acceptCoatingPlant() {
    if (this.state.coatingPlantLaditude == null ||
        this.state.coatingPlantLongitude == null) {
      this.props.showNotice('Valitse ensin kartalta aseman paikka', 'Warning');
      return;
    }

    this.setState({
      setCoatingPlantLaditude: this.state.coatingPlantLaditude,
      setCoatingPlantLongitude: this.state.coatingPlantLongitude,
      setName: this.state.name,
      setting: !this.state.setting,
    });

    this.props.setCoatingPlant(this.state.coatingPlantLaditude, this.state.coatingPlantLongitude,
                               this.state.name, this.props.index);
  }

  changeName(event) {
    this.setState({name: event.target.value});
  }

  render() {
    if (!this.state.setting) {
      return (
        <Selector name={this.state.setName}
                  latitude={this.state.setCoatingPlantLaditude}
                  longitude={this.state.setCoatingPlantLongitude}
                  toggleSetting={this.toggleSetting}
                  label={this.state.label} />
      );
    }

    return (
      <div>
        <Selector name={this.state.setName}
                  latitude={this.state.setCoatingPlantLaditude}
                  longitude={this.state.setCoatingPlantLongitude}
                  toggleSetting={this.toggleSetting}
                  label={this.state.label} />
        <div onClick={this.toggleSetting} className='modal'>
          <div id='coating-plant-select-modal' onClick={(e) => {e.stopPropagation();}}>
            <h4 className='center'>{this.state.title}</h4>
            <div style={{ height: this.state.height }}>
              <link rel="stylesheet" href="https://unpkg.com/leaflet@1.4.0/dist/leaflet.css"
                    integrity="sha512-puBpdR0798OZvTTbP4A8Ix/l+A4dHDD0DGqYW6RQ+9jxkRFclaxxQb/SJAWZfWAkuyeQUytO7+7N4QKrDh+drA=="
                    crossorigin="" />
              <Map ref={element => this.map = element} id='map-area' center={this.position} zoom={this.zoom}
                   maxZoom={this.state.maxZoom}
                   onClick={this.setCoatingPlant}>
                <TileLayer url={this.state.mapTilesUrl}
                           attribution={this.state.mapTilesAttribution}
                           maxZoom={this.state.maxZoom} />
                { this.state.coatingPlantLaditude != null ? (
                    <Marker position={[this.state.coatingPlantLaditude, this.state.coatingPlantLongitude]}
                            icon={new L.Icon({iconUrl: '../' + this.state.icon,
                                  iconSize: this.state.iconSize,
                                  iconAnchor: [this.state.iconSize[0] / 2, this.state.iconSize[1]],
                                  popupAnchor: [null, -50]
                                  })}>
                      <Popup autoPan={false}>
                        <span>{this.state.coatingPlantLaditude}, {this.state.coatingPlantLongitude}</span>
                      </Popup>
                    </Marker>
                  ) : null }
                { this.state.yourLaditude != null ? (
                    <Marker position={[this.state.yourLaditude, this.state.yourLongitude]}
                            icon={new L.Icon({iconUrl: '../your_location.gif',
                                  iconSize: [18, 43],
                                  iconAnchor: [9, 43],
                                  popupAnchor: [null, -43]
                                  })}>
                      <Popup>
                        <span>{this.state.yourLaditude}, {this.state.yourLongitude}</span>
                      </Popup>
                    </Marker>
                  ) : null }
                {this.trucks}
                {this.lines}
              </Map>
            </div>
            <br/>
            <label htmlFor='name'>Nimi:</label>
            <input id='name' type='text' value={this.state.name} onChange={this.changeName.bind(this)}/>
            <div id='coating-plant-select-bottom' className='row'>
              <div className='column'>
                <button onClick={this.acceptCoatingPlant}>
                  Aseta
                </button>
              </div>
              <div className='column'>
                <button className="button-outline" onClick={this.toggleSetting}>
                  Peruuta
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default connect(state => ({
}), { showNotice })(CoatingPlantSelect);
