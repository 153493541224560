import config from '../settings/config';
import React from "react";
import Signature from "./report/Signature";

const API_URL = 'https://' + config.api + '/nevipaver';
const API_AUTH_URL = 'https://' + config.api + '/auth';
const FILE_API_URL = 'https://' + config.cors + '/https://' +  config.fileApi;
const WS_URL = 'wss://' + config.socket;


export function Socket(url) {
    if (localStorage['login'] == null) {
        return null;
    }

    const socket = new WebSocket(WS_URL + url + '?x-nevia-auth=Bearer' + localStorage['login'].substring(7));
    socket.onopen = function(e) {
        console.log("SOCKET CONNECTED");
    }
    return socket;
}

export const timer = (time, data) => (
  new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(data);
    }, time); 
  })
);

export const transformErrors = errors => {
  return errors.map(error => {
    if (error.schema.messages && error.schema.messages[error.name][error.argument]) {
      return {
        ...error,
        message: error.schema.messages[error.name][error.argument],
      };
    }
    return error;
  });
};

// token is used in SignReport.js where user does not log in
export function fetch(url, method='GET', data=null, token=null) {
  let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'x-nevia-auth': token ? token : localStorage['login']
  };

  if (data != null) {
    data = JSON.stringify(data);
  }

  let apiUrl = API_URL;

  if (url.includes('/users') || url.includes('/permissions') ||
      url.includes('/contractpermissions') || url.includes('/organizations')) {
    apiUrl = API_AUTH_URL
  }
  
  return window.fetch(apiUrl + url, {
    method: method,
    headers: headers,
    body: data,
    mode: 'cors',
  }).then(async request => {
    if (!request.ok) {
        throw Error(request.status);
    }

    if (request.status !== 204) return request.json();
    return null;
  });
}

export function fetchFile(data, method, path) {
    let headers = {
        'x-nevia-auth': localStorage['login']
    };
    
    let url = FILE_API_URL + '/file';
  
    if (method === 'POST' || method === 'DELETE') {
        url += '?path=' + path;
    }
    else {
        url += '?file=' + path;
    }
  
    return window.fetch(url, {
        method: method || 'POST',
        headers: headers,
        body: data,
        mode: 'cors'
    }).then(request => {
        if (!request.ok) {
        throw Error(request.status);
        }

        if (method === 'GET') return request.blob();
        if (request.status !== 204) return request.json();
        return null;
    });
}

export function integerValue(value, defaultValue) {
  const parsedValue = parseInt(value, 10);

  if (isNaN(parsedValue)) {
    return defaultValue;
  }

  return parsedValue;
}


export function floatValue(value, defaultValue) {
  if (value == null) return defaultValue;
  const newValue = parseFloat(value);

  return newValue;
}

export function toRadians(angle) {
  return angle * (Math.PI / 180);
}

export function toDegrees(radians) {
  return radians * (180 / Math.PI);
};

const Ca = 6378137.0;
const Cf = 1.0 / 298.257223563;
const Ck0 = 0.9996;
const Clo0 = toRadians(27.0);
const CE0 = 500000.0;
const Cn = Cf / (2.0 - Cf);
const CA1 = Ca / (1.0 + Cn) * (1.0 + (Math.pow(Cn, 2.0)) / 4.0 + (Math.pow(Cn, 4.0)) / 64.0);
const Ce = Math.sqrt((2.0 * Cf - Math.pow(Cf, 2.0)));
const Ch1p = 1.0 / 2.0 * Cn- 2.0/3.0 * Math.pow(Cn, 2.0) + 5.0/16.0 * Math.pow(Cn, 3.0) + 41.0 / 180.0 * Math.pow(Cn, 4.0);
const Ch2p = 13.0 / 48.0 * Math.pow(Cn, 2.0) - 3.0 / 5.0 * Math.pow(Cn, 3.0) + 557.0 / 1440.0 * Math.pow(Cn, 4.0);
const Ch3p = 61.0 / 240.0 * Math.pow(Cn, 3.0) - 103.0 / 140.0 * Math.pow(Cn, 4.0);
const Ch4p = 49561.0 / 161280.0 * Math.pow(Cn, 4.0);
const Ch1 = 1.0 / 2.0 * Cn - 2.0 / 3.0 * (Math.pow(Cn, 2.0)) + 37.0 / 96.0 * (Math.pow(Cn, 3.0)) - 1.0 / 360.0 * (Math.pow(Cn, 4.0));
const Ch2 = 1.0 / 48.0 * (Math.pow(Cn, 2.0)) + 1.0 / 15.0 * (Math.pow(Cn, 3.0)) - 437.0 / 1440.0 * (Math.pow(Cn, 4.0));
const Ch3 = 17.0 / 480.0 * (Math.pow(Cn, 3.0)) - 37.0 / 840.0 * (Math.pow(Cn, 4.0));
const Ch4 = 4397.0 / 161280.0 * (Math.pow(Cn, 4.0));
const tm35fin_latitude_min = 6582464.0358;
const tm35fin_latitude_max = 7799839.8902;
const tm35fin_longitude_min = 50199.4814;
const tm35fin_longitude_max = 761274.6247;

export function toWGS84(y, x) {
  if (y < tm35fin_latitude_min || y > tm35fin_latitude_max ||
      x < tm35fin_longitude_min || x > tm35fin_longitude_max) {
    return {latitude: 0, longitude: 0};
  }

  const E = y / (CA1 * Ck0);
  const nn = (x - CE0) / (CA1 * Ck0);
  const E1p = Ch1 * Math.sin(2.0 * E) * Math.cosh(2.0 * nn);
  const E2p = Ch2 * Math.sin(4.0 * E) * Math.cosh(4.0 * nn);
  const E3p = Ch2 * Math.sin(6.0 * E) * Math.cosh(6.0 * nn);
  const E4p = Ch3 * Math.sin(8.0 * E) * Math.cosh(8.0 * nn);

  const nn1p = Ch1 * Math.cos(2.0 * E) * Math.sinh(2.0 * nn);
  const nn2p = Ch2 * Math.cos(4.0 * E) * Math.sinh(4.0 * nn);
  const nn3p = Ch3 * Math.cos(6.0 * E) * Math.sinh(6.0 * nn);
  const nn4p = Ch4 * Math.cos(8.0 * E) * Math.sinh(8.0 * nn);

  const Ep = E - E1p - E2p - E3p - E4p;

  const nnp = nn - nn1p - nn2p - nn3p - nn4p;
  const be = Math.asin(Math.sin(Ep) / Math.cosh(nnp));

  const Q = Math.asinh(Math.tan(be));
  let Qp = Q + Ce * Math.atanh(Ce * Math.tanh(Q));
  Qp = Q + Ce * Math.atanh(Ce * Math.tanh(Qp));
  Qp = Q + Ce * Math.atanh(Ce * Math.tanh(Qp));
  Qp = Q + Ce * Math.atanh(Ce * Math.tanh(Qp));

  const latitude = toDegrees(Math.atan(Math.sinh(Qp)));
  const longitude = toDegrees(Clo0 + Math.asin(Math.tanh(nnp) / Math.cos(be)));

  return {latitude: latitude, longitude: longitude};
}

export function toETRSTM35FIN(latitude, longitude) {
  const la = toRadians(latitude);
  const lo = toRadians(longitude);
  const Q = Math.asinh(Math.tan(la)) - Ce * Math.atanh(Ce * Math.sin(la));
  const be = Math.atan(Math.sinh(Q));
  const nnp = Math.atanh(Math.cos(be) * Math.sin(lo - Clo0));
  const Ep = Math.asin(Math.sin(be) * Math.cosh(nnp));
  const E1 = Ch1p * Math.sin(2.0 * Ep) * Math.cosh(2.0 * nnp);
  const E2 = Ch2p * Math.sin(4.0 * Ep) * Math.cosh(4.0 * nnp);
  const E3 = Ch3p * Math.sin(6.0 * Ep) * Math.cosh(6.0 * nnp);
  const E4 = Ch4p * Math.sin(8.0 * Ep) * Math.cosh(8.0 * nnp);
  const nn1 = Ch1p * Math.cos(2.0 * Ep) * Math.sinh(2.0 * nnp);
  const nn2 = Ch2p * Math.cos(4.0 * Ep) * Math.sinh(4.0 * nnp);
  const nn3 = Ch3p * Math.cos(6.0 * Ep) * Math.sinh(6.0 * nnp);
  const nn4 = Ch4p * Math.cos(8.0 * Ep) * Math.sinh(8.0 * nnp);
  const E = Ep + E1 + E2 + E3 + E4;
  const nn = nnp + nn1 + nn2 + nn3 + nn4;   
  const y = CA1* E * Ck0;
  const x = CA1* nn * Ck0+ CE0;

  if (y < tm35fin_latitude_min || y > tm35fin_latitude_max ||
      x < tm35fin_longitude_min || x > tm35fin_longitude_max) {
      return {x: 0, y: 0};
  }

  return {x: x, y: y};
}

 export function calculateOffsetCoordinates(latitude, longitude, offsetMetresX, offsetMetresY) {
  const R = 6371e3; // metres
  const newLatitude  = latitude  + (offsetMetresY / R) * (180 / Math.PI);
  const newLongitude = longitude + (offsetMetresX / R) * (180 / Math.PI) / Math.cos(latitude * Math.PI / 180);
  return {latitude: newLatitude, longitude: newLongitude};
}

function processFile(files) {
    const f = files[0];
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => resolve(event.target.result);
        reader.readAsDataURL(f);
    });
}

const ImageWidget = (props) => {
    return (
        <p>
            <input id={props.id} type="file"
               accept= "image/jpg, image/jpeg, image/png, image/gif"
               required={props.required}
               onChange={(event) =>
                   processFile(event.target.files).then(value => {
                       props.onChange(value);
                       let display = document.getElementById(props.id).nextSibling;
                       display.setAttribute("src",value);
                   })
               } />
            <img className="img-inform" src={props.value} alt=""/>
        </p>
    )
};

export function getPropertyFromJson(editingForm, sheet, field) {
    let cell = editingForm.sheets[sheet][field];
    let property = {schema:{"typeCache":cell.type}, ui: {}};
    let type;
    if (cell.type.indexOf("_")>-1) {
        type = cell.type.split("_")[0];
        let className = cell.type.split("_")[1];
        property.ui["classNames"] = className;
    } else {
        type = cell.type;
    }

    // if has options and it is array
    if (cell.options && !Array.isArray(cell.options) && cell.options !== "") {
        cell.options =  cell.options.split("|");
    }

    // set schema, ui and data
    if (type.toString().startsWith("Object")) {
        property.schema["type"] = "object";
        property.schema["properties"] = {};
        property.data = {};

        let i = 0;
        Object.keys(editingForm.sheets[type]).forEach(fieldName => {
            let subProp = getPropertyFromJson(editingForm, type, fieldName);
            property.schema.properties[fieldName] = subProp.schema;
            if (subProp.ui) {
                property.ui[fieldName] = subProp.ui;
            }
            if (cell.value && cell.value.length > i) {
                if (cell.value[i])
                    property.data[fieldName] = cell.value[i];
            } else if (subProp.value) {
                property.data[fieldName] = subProp.value;
            }
        })
    } else {
        switch (type) {
            case 'String': {
                property.schema["type"] = "string";
                if (cell.value) {
                    property.data = cell.value;
                }
                break;
            }
            case 'Number': {
                property.schema["type"] = "number";
                property.schema["minimum"] = 0;
                // property.ui["ui:widget"]= "updown";
                if (cell.value) {
                    property.data = cell.value;
                }
                break;
            }
            case 'Formula': {
                property.schema["type"] = "number";
                property.ui["ui:disabled"] = true;
                if (cell.value) {
                    property.data = cell.value;
                }
                break;
            }
            case 'Date': {
                property.schema["type"] = "string";
                property.schema["format"] = "date";
                if (cell.value) {
                    property.data = cell.value;
                }
                break;
            }
            case 'Checkbox':{
                property.schema["type"] = "array";
                property.schema["items"] = {"type": "string", "enum": cell.options?cell.options:["no options"]};
                property.schema["uniqueItems"] = true;
                property.ui["ui:widget"] = "checkboxes";
                property.ui["ui:options"] = {
                    "inline": true
                };
                if (cell.value) {
                    property.data = cell.value.split("|");
                }
                break;
            }
            case 'Boolean': {
                property.schema["type"] = "boolean";
                if (cell.value) {
                    property.data = cell.value;
                }
                break;
            }
            case 'Radio':{
                property.schema["type"] = "string";
                property.schema["enum"] = cell.options?cell.options:["no options"];
                property.ui["ui:widget"] = "radio";
                property.ui["ui:options"] = {
                    "inline": true,
                };
                if (cell.value) {
                    property.data = cell.value.split("|");
                }
                break;
            }
            case 'Image':{
                // property.schema = {
                //     "type":"object",
                //     "properties":{
                //         "image":{
                //             "title": " ",
                //             "type": "string",
                //             "format": "data-url"
                //         },
                //         "display": {
                //             "title": " ",
                //             "type": "string"
                //         }
                //     }
                // };
                // property.ui["image"] = {
                //     "ui:options": {
                //         "accept": [".jpg",".jpeg", ".png", "gif"]
                //     }
                // };
                // property.ui["display"] = {
                //     "classNames": "form-img",
                //     "ui:widget": (props) => {
                //         return (
                //             <img id={props.id}/>
                //         )
                //     }
                // };
                // if (cell.value && cell.value[0]) {
                //     property.data["image"] = cell.value[0]
                // }
                property.schema["type"] = "string";
                property.schema["format"] = "data-url";
                if (cell.value) {
                    property.data = cell.value
                }
                property.ui = {
                    "ui:widget": ImageWidget
                };
                break;
            }
            case 'Description': {
                property.schema["type"] = "object";
                property.ui["classNames"] = "description";
                break;
            }
            case 'Signature': {
                property.schema = {
                    "type": "object",
                    "properties":{
                        "signature": {
                            "title": " ",
                            "type":"string"
                        },
                        "email": {
                            "title": " ",
                            "type": "string",
                            "format": "email"
                        }
                    }
                };
                property.ui["email"] = {
                    "classNames":"signatureEmail",
                    "ui:placeholder":"Email Address"
                };
                property.ui["signature"] = {
                    "ui:widget": (props) => {
                        return (
                            <Signature parentOnChange = {props.onChange} dataUrl={props.value}/>
                        )
                    }
                };
                if (cell.value) {
                    property.data = {
                        "signature":cell.value[0],
                        "email":cell.value[1]?cell.value[1]:null
                    }
                }
                break;
            }
            case "Title1":{
                property.schema["type"] = "object";
                break;
            }
            default: {
                property.schema["type"] = "string";
                if (cell.value) {
                    property.data = cell.value;
                }
            }
        }
    }
    return property;
}

const field_types = {"String":"String", "Number":"Number", "Date":"Date", "Radio":"Radio", "Checkbox":"Checkbox", "Boolean":"Boolean", "Image":"Image", "Signature":"Signature", "Description":"Description", "Title":"Title1"};

export function getFieldTypes() {
    return field_types;
}

export function currentTime() {
    let date = new Date();
    let s = date.getFullYear() + (date.getMonth() < 10 ? '-0' : '-') + (date.getMonth()+1) +
            (date.getDate() < 10 ? '-0' : '-') + date.getDate() + (date.getHours() < 10 ? 'T0' : 'T') + 
            date.getHours() + (date.getMinutes() < 10 ? ':0' : ':') + date.getMinutes() + 
            (date.getSeconds() < 10 ? ':0' : ':') + date.getSeconds();
    return s;
}

export function currentTimeWithZ() {
    let date = new Date();
    let s = date.getFullYear() + (date.getUTCMonth() < 10 ? '-0' : '-') + (date.getUTCMonth()+1) + (date.getUTCDate() < 10 ? '-0' : '-') + date.getUTCDate() + (date.getUTCHours() < 10 ? 'T0' : 'T') + date.getUTCHours() + (date.getUTCMinutes() < 10 ? ':0' : ':') + date.getUTCMinutes() + (date.getUTCSeconds() < 10 ? ':0' : ':') + date.getUTCSeconds() + '.000Z';
    return s;
}

export function stateValueParser(event, type, defaultValue) {
    let value;

    if (type === 'integer') {
        value = integerValue(event.target.value, defaultValue);
    }
    else if (type === 'float') {
        value = event.target.value.replace(',', '.');
        const regExp = /^\d*\.?\d*$/;

        if (!regExp.test(value)) {
            return null;
        }

        if (value.length === 2 && value[0] === '0') {
        if (value[1] !== '.') {
            value = value[1];
        }
        }
    }
    else if (type === 'boolean') {
        value = event.target.checked;
    }
    else {
        value = event.target.value;
    }

    return value;
}