import React from 'react';
import {fetch, timer} from '../utils';
import {
    showMessage,
    List,
    showNotice
} from 'components';
import './Report.css';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import { Link} from 'react-router-dom';

class Reports extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            reports: [],
            header: ['report id', 'site', 'template', ''],
            fields: ['id', 'constructionSite.name', 'template.name','button#Muokkaa#/report/{id}'],
            allTemplates: [],
            selectedTemplate: null
        };

        this.getTemplates();
        this.getReports = this.getReports.bind(this);
    }

    componentDidMount() {
        timer(0).then(() => { // They get null sometimes without this.
            let site = null;

            if (this.props.selectedConstructionSite) {
                site = this.props.selectedConstructionSite.get('id');
            }

            this.getReports(site, this.state.selectedTemplate);
        });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.reports !== nextProps.reports) return;
        if (this.props.selectedContract === nextProps.selectedContract &&
            this.props.selectedConstructionSite === nextProps.selectedConstructionSite) return;
        let site = null;
        if (nextProps.selectedConstructionSite) {
            site = nextProps.selectedConstructionSite.get('id');
        }
        this.getReports(site, this.state.selectedTemplate);
    }

    changeTemplate(templateId) {
        this.setState({
            selectedTemplate: templateId,
        });
        let site = null;
        if (this.props.selectedConstructionSite) {
            site = this.props.selectedConstructionSite.get('id');
        }
        this.getReports(site, templateId);
    }

    getReports(site, template) {
        this.setState({ loading: true });
        
        let url = '/report/info';
        let params = '';
        if (site != null && typeof site === 'number') params = 'site=' + site;
        if (template != null && typeof site === 'number') {
            if (params.length > 0)
                params = params + '&';
            params = params + 'template=' + template;
        }

        if (params.length > 0)
            url = url + '?' + params;
        fetch(url).then(data => {
            this.setState({
                reports: data,
                loading: false
            });
        }).catch(error => {
            this.props.showNotice('Palvelimeen ei saatu yhteyttä', 'Error');
            this.setState({
                loading: false
            });
        });
    }

    getTemplates() {
        fetch('/template').then(data => {
            this.setState({
                allTemplates: data,
                loadingTemplate: false
            });
        }).catch(error => {
            this.props.showNotice('Palvelimeen ei saatu yhteyttä', 'Error');
            this.setState({
                loadingTemplate: false
            });
        });
    }

    render() {
        // if (this.props.reports == null) return null;

        return (
            <div>
                <div className="container">
                    <h1>Raportti</h1>
                    <div className="center">
                        <Link to="/reports/new">
                            <button type="button">Uusi raportti</button>
                        </Link>
                    </div>
                    <div className="row">
                        <div className="column">
                            <label>Template</label>
                            <select value={this.state.selectedTemplate}
                                    onChange={(e) => this.changeTemplate(e.target.value)}>
                                <option value=''>ALL</option>
                                {this.state.allTemplates.map(temp =>
                                    <option value={temp.id}>{temp.name}</option>)}
                            </select>
                        </div>
                    </div>
                    <List many={10}
                          emptyText={'Ei raportti'}
                          header={this.state.header}
                          fields={this.state.fields}
                          data={fromJS(this.state.reports)}/>
                </div>
            </div>
        );
    }
}

export default connect(state => ({
    selectedContract: state.contractSelect.get('selectedContract'),
    selectedConstructionSite: state.constructionSiteSelect.get('selectedConstructionSite'),
}), { showMessage, showNotice })(Reports);