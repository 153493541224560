import React from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { showNotice, showMessage } from 'components';
import { fetch } from '../utils';
import Form from "@rjsf/core";
import { permissionNames } from './Users';


class UserNew extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      schema: null,
      uiSchema: {},
      formData: {},
      permissions: [],
      contracts: [],
      loading: false
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount () {
    if (this.props.user != null) {
      this.setForm(this.props.user);
    }
  }

  componentDidUpdate (lastProps, lastState) {
    if (this.props.schema === null && this.props.user != null) {
      this.setForm(this.props.user);
    }
  }

  async setForm (user) {
    let permissionValue = [];
    let permissions = [];

    user.get('permissions').forEach(permission => {
      const name = permissionNames[permission.get('permission')];

      if (name != null) {
        permissionValue.push(permission.get('permission'));
        permissions.push(name);
      }
    });

    let contractValue = [];
    let contracts = [];

    this.props.contracts.forEach(contract => {
      contractValue.push(contract.get('id'));
      contracts.push(contract.get('name'));
    });

    let schema = {
      type: "object",
      required: ["email", 'firstName', 'lastName', "password", "passwordConfirm"],
      properties: {
        email: { type: "string", title: "Sähköposti:" },
        firstName: { type: "string", title: "Etunimi:" },
        lastName: { type: "string", title: "Sukunimi:" },
        password: { type: "string", title: "Salasana:" },
        passwordConfirm: { type: "string", title: "Vahvista salasana:" },
        permissions: {
          "title": "Oikeudet",
          "type": "array",
          "items": {
            "type": "number",
            "enum": permissionValue,
            "enumNames": permissions
          },
          "uniqueItems": true
        },
        orderer: { type: "boolean", title: "Tilaaja" },
        contractPermissions: {
          "title": "Urakka kohtaset oikeudet:",
          "type": "array",
          "items": {
            "type": "number",
            "enum": contractValue,
            "enumNames": contracts
          },
        }
      },
    };

    if (user.get('organizationId') === 1) {
      const organizations = await this.getOrganizations();
      let organizationValues = [];
      let organizationNames = [];

      organizations.forEach(organization => {
        organizationValues.push(organization.id);
        organizationNames.push(organization.name);
      });

      schema.properties = Object.assign(
        {
          organization: { type: "number", enum: organizationValues, enumNames: organizationNames, title: "Organisaatio:" },
        }, schema.properties
      );

      schema.required.unshift("organization");
    }

    const uiSchema = {
      permissions: {
        "ui:widget": "checkboxes",
      },
      password: {
        "ui:widget": "password",
      },
      passwordConfirm: {
        "ui:widget": "password",
      },
      contractPermissions: {
        "ui:options": {
          "orderable": false
        },
      }
    };

    this.setState({
      schema: schema,
      uiSchema: uiSchema
    });
  }

  async getOrganizations () {
    try {
      const data = await fetch('/organizations');
      return data;
    } catch (error) {
      this.props.showMessage('Virhe', 'Palvelimelta ei saatu Organisaatioita', 'Error');
    }

    return [];
  }

  onSubmit ({ formData }) {
    if (formData.password !== formData.passwordConfirm) {
      this.props.showNotice('Salasanat eivät täsmää', 'Warning');
      return;
    }

    this.setState({ loading: true });

    let user = {
      email: formData.email,
      firstName: formData.firstName,
      lastName: formData.lastName,
      password: formData.password,
      organizationId: formData.organization || this.props.user.get('organizationId'),
      orderer: formData.orderer
    };

    fetch('/users', 'POST', user).then(async data => {
      for (let index in formData.permissions) {
        await fetch('/permissions/' + formData.permissions[index] + '/user/' + data.id, 'POST');
      }

      for (let index in formData.contractPermissions) {
        await fetch('/contractpermissions/' + formData.contractPermissions[index] + '/user/' + data.id, 'POST');
      }

      this.props.showNotice('Käyttäjä lisätty', 'Ok');
      this.setState({ redirect: true });
    }).catch(error => {
      this.setState({
        loading: false,
        formData: formData
      });

      if (error.message === 'org.springframework.dao.DataIntegrityViolationException') {
        this.props.showMessage('Virhe', 'Joitain tietoja puuttuu', 'Warning');
      }
      else if (error.message === 'org.springframework.dao.DuplicateKeyException') {
        this.props.showMessage('Virhe', 'Tällä sähköpostilla on jo olemassa käyttäjä', 'Warning');
      }
      else {
        this.props.showMessage('Virhe', 'Yhteys virhe', 'Error');
      }
    });
  }

  render () {
    if (this.state.redirect) return <Navigate to='/users' push />;

    if (this.state.schema == null) {
      return (
        <div className='main-client-container'>
          <div className='loader'></div>
        </div>
      );
    }

    return (
      <div className='main-client-container'>
        <h1>Lisää käyttäjä</h1>
        <Form schema={this.state.schema} uiSchema={this.state.uiSchema}
          onSubmit={this.onSubmit} formData={this.state.formData}
          onChange={this.onChange} showErrorList={false}>
          {this.state.loading ?
            <div className='loader' />
            :
            <div>
              <input className='button-primary' type='submit' value='Lisää' />
              <Link className='button-primary' to='/users/'>
                <button className='button-outline'>Peruuta</button>
              </Link>
            </div>
          }
        </Form>
      </div>
    );
  }
}


export default connect(state => ({
  user: state.login.get('user'),
  contracts: state.contractSelect.get('contracts'),
}), { showNotice, showMessage })(UserNew);
