import React from "react";
import { connect } from "react-redux";
import {showMessage, showNotice, List} from "components";
import {fetch} from "../utils";
import {Link} from "react-router-dom";
import {fromJS} from "immutable";
import './Report.css';

class Templates extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            header: ['template id', 'name', 'title', 'form id', ''],
            fields: ['id', 'name', 'title', 'title', 'button#Muokkaa#/template/{id}'],
            templates: [],
        };
        this.getTemplates();
    }

    getTemplates() {
        this.setState({ loading: true });
        let url = '/template';
        fetch(url).then(data => {
            this.setState({
                templates: data,
                loading: false
            });
        }).catch(error => {
            this.props.showNotice('Palvelimeen ei saatu yhteyttä', 'Error');
            this.setState({
                loading: false
            });
        });
    }

    render() {
        return (
            <div>
                <div className="container">
                    <h1>Templates</h1>
                    <div className="center">
                        <Link to="/templates/new">
                            <button type="button">Uusi template</button>
                        </Link>
                    </div>
                    <List many={10}
                          emptyText={'Ei templates'}
                          header={this.state.header}
                          fields={this.state.fields}
                          data={fromJS(this.state.templates)}/>
                </div>
            </div>
        );
    }

}

export default connect(state => ({
}), { showMessage, showNotice })(Templates);