import React from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import CoatingPlantSelect from './CoatingPlantSelect'
import { showNotice, showMessage, showConfirm, selectContract } from 'components';
import { fetch } from '../utils';
import config from '../../settings/config';
import './ConstructionSites.css';
import Form from '@rjsf/core';


class ConstructionSiteEdit extends React.Component {
  constructor(props) {
    super(props);

    const MassPermission = this.props.permissions != null ? this.props.permissions.find(permission => permission.get('permission') === 2) != null : false;

    this.MACADAM = config.reptail || !MassPermission;

    this.ROADHAND_PERMISSION = this.props.permissions != null ?
      this.props.permissions.find(permission => permission.get('permission') === 8) != null
      :
      false;

    if (!this.MACADAM) {
      this.getMassTypes();
    }

    this.getTrucks();
    this.getContracts();
    this.getSite(parseInt(this.props.params.id, 10));

    this.schema = null;
    this.formData = {};

    this.state = {
      massTypes: [],
      allTrucks: [],
      contracts: [],
      loadingTrucks: true,
      editSite: null,
      coatingPlants: [{}]
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.setCoatingPlant = this.setCoatingPlant.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.delete = this.delete.bind(this);
  }


  componentWillUpdate (nextProps, nextState) {
    if (this.schema === null && nextState.editSite != null && (this.MACADAM || nextState.massTypes.length !== 0) &&
      nextState.contracts.length !== 0 && !nextState.loadingTrucks) {
      this.setForm(nextState.editSite, nextState.massTypes, nextState.contracts, nextState.allTrucks);
    }
  }


  setForm (editingSite, massTypesAll, contractsAll, trucksAll) {
    let contractsID = [];
    let contracts = [];

    contractsAll.forEach(contract => {
      contractsID.push(contract['id']);
      contracts.push(contract['name']);
    });

    let massTypes = [];

    massTypesAll.forEach(type => {
      massTypes.push(type['name']);
    });

    let allTrucksID = [];
    let allTrucks = [];

    trucksAll.forEach(truck => {
      allTrucksID.push(truck['id']);
      allTrucks.push(truck['register_number']);
    });

    this.schema = {
      type: "object",
      required: ["contract", "name"],
      properties: {
        contract: {
          type: "number", enum: contractsID, enumNames: contracts,
          title: "Urakka:"
        },
        name: { type: "string", title: "Nimi:" },
        customId: { type: "string", title: "Kohdetunnus:" },
        road: { type: "integer", title: "Tie:" },
        startPart: { type: "integer", title: "Aloitus tieosa:" },
        startPole: { type: "integer", title: "Aloitus paalu:" },
        endPart: { type: "integer", title: "Lopetus tieosa:" },
        endPole: { type: "integer", title: "Lopetus paalu:" },
        trucks: {
          "title": "Rekat",
          "type": "array",
          "items": {
            "type": "number", "enum": allTrucksID, "enumNames": allTrucks
          }
        },
      }
    };

    if (!this.MACADAM) {
      this.schema.required.concat(["massType", "operationType", "flatness"]);
      this.schema.properties.massType = { type: "string", enum: massTypes, enumNames: massTypes, title: "Massatyyppi:" };
      this.schema.properties.operationType = { type: "string", title: "Toimenpidetyyppi:" };
      this.schema.properties.flatness = { type: "string", title: "Kg / m²:" };
    }

    if (this.ROADHAND_PERMISSION) {
      this.schema.properties.lane = { type: "number", title: "Kaista:" };
      this.schema.properties.length = { type: "number", title: "Pituus:" };
      this.schema.properties.quality = { type: "string", title: "Laatuluokka:" };
      this.schema.properties.kvl = { type: "number", title: "KVL:" };
      this.schema.properties.comments = { type: "string", title: "Kommentit:" };
    }

    this.uiSchema = {
      registerNumber: {
        "ui:placeholder": "ABC-123",
        "ui:autofocus": true
      },
      road: {
        "ui:widget": "updown"
      },
      trucks: {
        "ui:options": {
          "orderable": false
        }
      }
    };

    let trucks = [];

    editingSite['trucks'].forEach(truck => {
      trucks.push(truck['id'])
    });

    this.formData = {
      contract: editingSite['contract']['id'],
      name: editingSite['name'],
      road: editingSite['road_number'],
      startPart: editingSite['start_part'],
      startPole: editingSite['start_distance'],
      endPart: editingSite['end_part'],
      endPole: editingSite['end_distance'],
      massType: editingSite['mass_type'],
      operationType: editingSite['operation_type'],
      flatness: editingSite['flatness'],
      customId: editingSite['custom_id'],
      lane: editingSite['lane'],
      length: editingSite['length'],
      quality: editingSite['quality'],
      kvl: editingSite['kvl'],
      comments: editingSite['comments'],
      trucks: trucks,
    };
  }


  getMassTypes () {
    fetch('/masses/types/').then(data => {
      this.setState({
        massTypes: data,
      });
    }).catch(error => {
      this.props.showNotice('Palvelimeen ei saatu yhteyttä', 'Error');
    });
  }


  getTrucks () {
    fetch('/trucks/').then(data => {
      this.setState({
        allTrucks: data,
        loadingTrucks: false
      });
    }).catch(error => {
      this.props.showMessage('Virhe', 'Palvelimeen ei saatu yhteyttä', 'Error');
      this.setState({
        loadingTrucks: false
      });
    });
  }

  getContracts () {
    fetch('/contracts/').then(data => {
      this.setState({
        contracts: data,
      });
    }).catch(error => {
      this.props.showMessage('Virhe', 'Palvelimeen ei saatu yhteyttä', 'Error');
    });
  }

  getSite (id) {
    fetch('/constructionsites/' + id + '/').then(data => {
      this.setState({
        editSite: data,
      });
    }).catch(error => {
      this.props.showNotice('Kohdetta ei löytynyt', 'Warning');
      this.setState({
        redirect: true,
      });
    });
  }

  onSubmit ({ formData }) {
    let site = {
      id: this.state.editSite['id'],
      name: formData.name,
      road_number: formData.road,
      contract_id: formData.contract,
      mass_type: formData.massType,
      operation_type: formData.operationType,
      flatness: formData.flatness,
      trucks: formData.trucks,
      start_part: formData.startPart,
      end_part: formData.endPart,
      start_distance: formData.startPole,
      end_distance: formData.endPole,
      coating_plant_laditude: this.state.coatingPlantLaditude,
      coating_plant_longitude: this.state.coatingPlantLongitude
    };

    if (this.ROADHAND_PERMISSION) {
      site.custom_id = formData.customId;
      site.lane = formData.lane;
      site.length = formData.length;
      site.quality = formData.quality;
      site.kvl = formData.kvl;
      site.comments = formData.comments;
    }

    fetch('/constructionsites/' + site['id'] + '/', 'PATCH', site).then(async data => {
      for (let index in this.state.coatingPlants) {
        const plant = this.state.coatingPlants[index];

        if (plant.latidute == null) {
          continue;
        }

        const url = !this.MACADAM ? '/coatingplant' : '/pit';
        await fetch(url, 'POST', plant);
      }
      this.props.showNotice('Kohteen muokkaaminen onnistui', 'Ok');
      this.setState({
        redirect: true,
      });
    }).catch(error => {
      this.props.showNotice('Kohteen muokkaaminen epäonnistui', 'Error');
    });
  }

  confirmDelete (event) {
    event.preventDefault();
    this.props.showConfirm('Poistetaanko tämä (' + this.state.editSite['name'] + ') kohde?', this.delete);
  }


  delete () {
    fetch('/constructionsites/' + this.state.editSite['id'] + '/', 'DELETE').then(data => {
      this.props.showNotice('Kohde poistettu', 'Ok')
      this.setState({
        redirect: true,
      });
    }).catch(error => {
      if (error.message === '409') {
        this.props.showMessage('Virhe', 'Kohteseen kuuluu asioita, jonka takia sitä ei voida poistaa', 'Error');
      }
      else {
        this.props.showMessage('Virhe', 'Kohteen poisto epäonnistui', 'Error');
      }
    });
  }

  setCoatingPlant (latidute, longitude, name, index) {
    let coatingPlants = this.state.coatingPlants;

    coatingPlants[index] = {
      latidute: latidute,
      longitude: longitude,
      name: name || null
    };

    this.setState({ coatingPlants: coatingPlants });
  }

  addNewCoatingPlantRow () {
    this.setState({
      coatingPlants: this.state.coatingPlants.concat({}),
    });
  }

  render () {
    if (this.state.redirect) return <Navigate to='/sites' push />;
    if (this.schema == null) {
      return (
        <div className="main-client-container">
          <h2>Ladataan tietoja...</h2>
          <div className="loader"></div>
        </div>
      );
    }
    return (
      <div className="main-client-container">
        <h1>Muokkaa kohdetta</h1>
        <h2>{this.state.editSite['name']}</h2>
        <Form schema={this.schema} uiSchema={this.uiSchema}
          onSubmit={this.onSubmit} formData={this.formData}
          onChange={({ formData }) => {
            this.formData = formData;
            this.props.selectContract(this.formData.contract);
          }}
          showErrorList={false}>
          {
            this.state.coatingPlants.map((plant, index) => (
              <CoatingPlantSelect setCoatingPlant={this.setCoatingPlant}
                macadam={this.MACADAM}
                index={index} />
            ))
          }
          <button className='button-outline' onClick={this.addNewCoatingPlantRow}>
            Lisää uusi rivi
          </button>
          <br />
          <input className='button-primary' type='submit' value='Tallenna kohde' />
          <Link className='button-primary' to='/sites/'>
            <button className='button-outline'>Peruuta</button>
          </Link>
          <button className='float-right red-text button-outline' onClick={this.confirmDelete}>
            Poista
          </button>
        </Form>
      </div>
    );
  }
}

export default connect(state => ({
  permissions: state.login.get('user') == null ? null : state.login.get('user').get('permissions'),
}), { showNotice, showMessage, showConfirm, selectContract })(ConstructionSiteEdit);
