import * as XLSX from 'xlsx'

const paddedNumber = number => number <= 99 ? ('0' + number).slice(-2) : number;

export function paverExcel (masses, contract, site) {
  let array = [['LEVITYSRAPORTTI'], []];
  let wscols = [
    { wch: 15 }, { wch: contract.length < 16 ? 16 : contract.length },
    { wch: 13 }, { wch: 15 },
    { wch: site.toString().length > 16 ? site.toString().length : 16 }, { wch: 5 },
    { wch: 26 }, { wch: 7 },
    { wch: 19 }, { wch: 17 }
  ];

  array.push(['URAKKA:', contract, null, 'TYÖKOHDE:', site,
    null, 'AUTOMÄÄRÄ:', masses.length]);

  const startTime = new Date(masses[0]['date']);
  const endTime = new Date(masses[masses.length - 1]['date']);
  const startTimeText = startTime.getDate() + '.' + (startTime.getMonth() + 1) + '.' + startTime.getFullYear() +
    ' ' + paddedNumber(startTime.getHours()) + ':' + paddedNumber(startTime.getMinutes());
  const endTimeText = endTime.getDate() + '.' + (endTime.getMonth() + 1) + '.' + endTime.getFullYear() +
    ' ' + paddedNumber(endTime.getHours()) + ':' + paddedNumber(endTime.getMinutes());

  let temperatureLowest = 1000;
  let temperatureHighest = 0;
  let combinedMasses = [];
  let combinedMassSmallAreas = 0;
  let combinedMassSmallAreaMass = 0;
  let combinedMassLevelingMass = 0;
  let allLength = 0;
  let allArea = 0;
  let allMass = 0;

  masses.forEach(mass => {
    if (mass.temperature < temperatureLowest) {
      temperatureLowest = mass.temperature;
    }
    else if (mass.temperature > temperatureHighest) {
      temperatureHighest = mass.temperature;
    }

    for (let index in mass.paths) {
      const path = mass.paths[index];

      if (!path.calculated_kg_per_square) {
        continue;
      }

      mass.length += path.length;

      if (mass.area == null) {
        mass.area = path.area;
      }
      else {
        mass.area += path.area;
      }

      if (combinedMasses.length !== 0) {
        let lastMass = combinedMasses[combinedMasses.length - 1];

        if (lastMass.lane !== path.lane || lastMass.end_part !== path.start_part ||
          lastMass.width !== path.width || lastMass.mass_per_square !== path.mass_per_square) {
          path.calculated_kg_per_square = Math.round(path.calculated_kg_per_square);
          path.mass = path.estimated_mass;
          combinedMasses.push(path);
        }
        else {
          lastMass.end_distance = path.end_distance;
          lastMass.length += path.length;
          lastMass.area += path.area;
          lastMass.mass += path.estimated_mass;
          if (path.calculated_kg_per_square != null) {
            lastMass.calculated_kg_per_square = Math.round(lastMass.mass * 1000 / lastMass.area);
          }
          combinedMasses[combinedMasses.length - 1] = lastMass;
        }
      }
      else {
        path.calculated_kg_per_square = Math.round(path.calculated_kg_per_square);
        path.mass = path.estimated_mass;

        combinedMasses.push(path);
      }
    }

    combinedMassSmallAreas += mass.small_areas;
    combinedMassSmallAreaMass += mass.small_areas * mass.paths[mass.paths.length - 1].mass_per_square;
    combinedMassLevelingMass += mass.leveling_mass;
    allLength += mass.length;

    if (mass.area) {
      allArea += mass.area;
    }

    allMass += mass.mass;
  });

  if (temperatureLowest === 1000 || temperatureHighest === 0) {
    temperatureLowest = '-'
    temperatureHighest = '-'
  }

  array.push(['ALOITUS AIKA:', startTimeText, null, 'LOPETUS AIKA:', endTimeText,
    null, 'MASSAN LÄMPÖTILA max/min:', temperatureHighest + '/' + temperatureLowest]);
  array.push([]);
  array.push(['Kaista', 'Tieosa', 'Aloituspaalu', 'Lopetuspaalu', 'Leveys', 'Pituus',
    'Neliöt', 'Tonnit', 'Teoriittinen kg/m²', 'Toteutunut kg/m²']);

  combinedMasses.forEach(mass => {
    array.push([mass.lane, mass.end_part, mass.start_distance,
    mass.end_distance, mass.width, mass.length,
    mass.area, mass.mass, mass.mass_per_square,
    mass.calculated_kg_per_square]);
  });

  array.push([null, null, null, 'Pienalueet', null, null, combinedMassSmallAreas,
    combinedMassSmallAreaMass, null, combinedMassSmallAreaMass / combinedMassSmallAreas || null]);
  array.push([null, null, null, 'TAS', null, null, null,
    combinedMassLevelingMass]);

  const everythingArea = allArea + combinedMassSmallAreas;
  const everythingMass = allMass + combinedMassSmallAreaMass + combinedMassLevelingMass;
  array.push([null, null, null, null, 'YHTEENSÄ', allLength, everythingArea,
    everythingMass, null, Math.round(everythingMass * 1000 / everythingArea)]);

  const worksheet = XLSX.utils.aoa_to_sheet(array);
  const new_workbook = XLSX.utils.book_new();

  worksheet['!cols'] = wscols;

  XLSX.utils.book_append_sheet(new_workbook, worksheet, 'Levitysraportti');
  XLSX.writeFile(new_workbook, 'Levitysraportti - ' + site + '.xlsx')
}