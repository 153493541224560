import React from 'react';
import { connect } from 'react-redux';
import { Excel, showMessage, showNotice } from 'components';
import { paverExcel } from './ExcelReports';
import { fetch } from '../utils';
import { fromJS } from 'immutable';
import './Report.css';
import TimeRange from '../timeRange/TimeRange'

const StartView = props => {
  if (!props.show) return null;

  return (
    <div id='app-container'>
      <h2>Luo raportti</h2>
      {
        props.buttons.map(application => (
          <div className='app-button' key={application.name}
            onClick={props.setApplicationChoices.bind(null, application.choices)}>
            <img src={'images/' + application.icon} alt='' />
            <p>{application.name}</p>
          </div>
        ))
      }
    </div>
  );
}


const ChooseType = props => {
  console.log(props)
  return (
    <div id='app-container'>
      <h2>Valitse raporttityyppi</h2>
      <TimeRange />
      {
        Object.entries(props.choices).map(choice => {
          const name = choice[0];
          const action = choice[1];
          return (
            <div className='text-button' key={name}
              onClick={action}>
              <p>{name}</p>
            </div>
          )
        })
      }
      <br />
      <br />
      <button className='button-outline' onClick={props.back}>Takaisin</button>
    </div>
  );
}


class Report extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showStartView: true,
      applications: []
    };

    this.setApplicationChoices = this.setApplicationChoices.bind(this);
    this.clearApplicationChoices = this.clearApplicationChoices.bind(this);
    this.getMasses = this.getMasses.bind(this);
    this.toggleCustomExcel = this.toggleCustomExcel.bind(this);
    this.createPaverExcel = this.createPaverExcel.bind(this);
    this.customExcelMass = this.customExcelMass.bind(this);
    this.getMacadams = this.getMacadams.bind(this);
    this.customExcelMacadam = this.customExcelMacadam.bind(this);
    this.getTrafficSigns = this.getTrafficSigns.bind(this);
    this.customExcelTrafficSign = this.customExcelTrafficSign.bind(this);
  }


  componentWillMount () {
    /**
     * 0 = Manager
     * 1 = Tracker
     * 2 = Mass
     * 3 = Macadam
     * 4 = NeviSign
     * 5 = NeviObs
    */
    let applications = [];

    this.props.permissions.sort((a, b) => a.get('permission') > b.get('permission')).forEach(field => {
      const permission = field.get('permission');
      if (permission === 1) {
        /*
        applications.push(
          {
            name: 'Tracker',
            icon: 'tracker.gif',
            choices: {}
          }
        );
        */
      }
      else if (permission === 2) {
        applications.push(
          {
            name: 'Massa',
            icon: 'paver.gif',
            choices: {
              'Levitysraportti': this.createPaverExcel,
              'Custom': this.customExcelMass
            }
          }
        );
        applications.push(
          {
            name: 'Asema',
            icon: 'coating_plant.gif',
            choices: {}
          }
        );
      }
      else if (permission === 3) {
        applications.push(
          {
            name: 'Murske',
            icon: 'macadam.gif',
            choices: { 'Custom': this.customExcelMacadam }
          }
        );
      }
      else if (permission === 4) {
        applications.push(
          {
            name: 'Merkki',
            icon: 'sign.gif',
            choices: { 'Custom': this.customExcelTrafficSign }
          }
        );
      }
    });

    let constructionSiteName = null;

    if (this.props.selectedConstructionSite != null) {
      constructionSiteName = this.props.selectedConstructionSite.get('name').length >= 31 ?
        this.props.selectedConstructionSite.get('id')
        :
        this.props.selectedConstructionSite.get('name');
    }

    this.setState({
      applications: applications,
      constructionSiteName: constructionSiteName
    });
  }

  componentDidUpdate (lastProps) {
    if (lastProps.selectedConstructionSite !== this.props.selectedConstructionSite) {
      let constructionSiteName = null;

      if (this.props.selectedConstructionSite != null) {
        constructionSiteName = this.props.selectedConstructionSite.get('name').length >= 31 ?
          this.props.selectedConstructionSite.get('id')
          :
          this.props.selectedConstructionSite.get('name');
      }

      this.setState({ constructionSiteName: constructionSiteName });
    }
  }

  setApplicationChoices (choices) {
    this.setState({ choices: choices });
  }

  clearApplicationChoices () {
    this.setState({ choices: null });
  }

  async getMasses () {
    let masses = [];

    try {
      const allPaths = await fetch('/paths/mass/site/' + this.props.selectedConstructionSite.get('id'));

      let url = '/masses?site=' + this.props.selectedConstructionSite.get('id');

      if (this.props.timeRangeStart !== '') {
        url += '&timestart=' + this.props.timeRangeStart + '&timeend=' + this.props.timeRangeEnd;
      }

      const data = await fetch(url);

      for (let index in data.reverse()) {
        let mass = data[index];

        if (mass.paths_id) {
          try {
            const paths = allPaths.filter(path => path.paths_id === mass.paths_id);
            let massWholeArea = 0;
            let roadPaths = '';
            let roadPositions = '';
            let length = 0;

            for (let index in paths) {
              const path = paths[index];

              if (path.area) {
                massWholeArea += path.area;
              }

              roadPaths += path.start_part + ' / ' + path.start_distance + ' - ' +
                (path.end_part ? (path.end_part + ' / ' + path.end_distance) + ' , ' : '');
              roadPositions += path.direction + ' / ' + path.roadway + ' / ' + path.lane +
                ' , ';
              length += path.length;
            }

            mass.roadPaths = roadPaths.substring(0, roadPaths.length - 3);
            mass.roadPositions = roadPositions.substring(0, roadPositions.length - 3);
            mass.length = length;

            if (massWholeArea !== 0) {
              const calculatedKgPerSquare = mass.mass * 1000 / massWholeArea;
              mass.calculated_kg_per_square = Math.round(calculatedKgPerSquare);
              let weightedSumMassPerSquare = 0;

              paths.forEach((path, index) => {
                if (path.area !== 0) {
                  const ratio = path.area / massWholeArea;
                  weightedSumMassPerSquare += path.mass_per_square * ratio;

                  const estimatedMass = path.area / massWholeArea * mass.mass;
                  path.estimated_mass = estimatedMass;
                  path.calculated_kg_per_square = Math.round(estimatedMass * 1000 / path.area);
                  paths[index] = path;
                }
              });

              mass.mass_per_square = weightedSumMassPerSquare;
            }

            mass.paths = paths;
          } catch (error) {
            mass.paths = [];
          }
        }
        else {
          mass.paths = [];
        }
      }

      masses = data;
    } catch (error) {
      this.props.showMessage('Virhe', 'Palvelin virhe', 'Error');
    }

    return masses;
  }

  toggleCustomExcel () {
    this.setState({
      showCustomExcel: !this.state.showCustomExcel
    });
  }

  async createPaverExcel () {
    if (this.props.selectedConstructionSite == null) {
      this.props.showNotice('Valitse ensin haluttu kohde', 'Warning');
      return;
    }

    const masses = await this.getMasses();

    if (masses.length === 0) {
      this.props.showNotice('Massoja ei ole yhtään', 'Warning');
      return;
    }

    const contract = this.props.contracts.find(contract => contract.get('id') === this.props.selectedContract);
    paverExcel(masses, contract.get('name'), this.state.constructionSiteName);
  }

  async customExcelMass () {
    if (this.props.selectedConstructionSite == null) {
      this.props.showNotice('Valitse ensin haluttu kohde', 'Warning');
      return;
    }

    const masses = await this.getMasses();

    if (masses.length === 0) {
      this.props.showNotice('Massoja ei ole yhtään', 'Warning');
      return;
    }

    this.setState({
      customName: this.state.constructionSiteName + ' - Raportti',
      customHeaders: ['Kohde', 'Aika', 'Aloitus - lopetus', 'Suunta / ajorata / kaista', 'Rekka',
        'Kuorman massa', 'Tiehen käytetty massa', 'Tasoitus massa', 'Pienalueet (m²)', 'Pituus (m)',
        'Kg / m²', 'Saatu Kg / m²', 'Huomiot'],
      customDataHeaders: ['construction_site.name', 'date', 'roadPaths', 'roadPositions', 'truck.register_number',
        'truck_mass', 'mass', 'leveling_mass', 'small_areas', 'length',
        'mass_per_square', 'calculated_kg_per_square', 'attentions'],
      customTimeField: 'date',
      customData: fromJS(masses),
      showCustomExcel: true
    });
  }

  async getMacadams () {
    let macadams = [];
    let url = '/macadams?site=' + this.props.selectedConstructionSite.get('id');

    if (this.props.timeRangeStart !== '') {
      url += '&timestart=' + this.props.timeRangeStart + '&timeend=' + this.props.timeRangeEnd;
    }

    try {
      const data = await fetch(url);
      macadams = data;
    } catch (error) {
      this.props.showMessage('Virhe', 'Palvelin virhe', 'Error');
    }

    return macadams;
  }

  async customExcelMacadam () {
    if (this.props.selectedConstructionSite == null) {
      this.props.showNotice('Valitse ensin haluttu kohde', 'Warning');
      return;
    }

    const macadams = await this.getMacadams();

    if (macadams.length === 0) {
      this.props.showNotice('Murskeita ei ole yhtään', 'Warning');
      return;
    }

    this.setState({
      customName: this.state.constructionSiteName + ' - Raportti',
      headers: ['Kohde', 'Aika', 'Tienumero', 'Tieosa', 'Paalu',
        'Lopetus tieosa', 'lopetus paalu', 'Ajorata', 'Suunta', 'Kaista', 'Rekka',
        'Kuorman massa', 'Kerroin', 'Korkeus (mm)', 'Lajike', 'Monttu', 'Huomiot'],
      dataHeaders: ['construction_site.name', 'date', 'road_number', 'road_part', 'road_distance',
        'end_road_part', 'end_road_distance', 'roadway', 'direction', 'lane', 'truck.register_number',
        'truck_mass', 'multiplier', 'height', 'variety', 'pit', 'attentions'],
      customTimeField: 'date',
      customData: fromJS(macadams),
      showCustomExcel: true
    });
  }

  async getTrafficSigns () {
    let signs = [];
    let url = '/trafficsigns?siteId=' + this.props.selectedConstructionSite.get('id');

    if (this.props.timeRangeStart !== '') {
      url += '&timestart=' + this.props.timeRangeStart + '&timeend=' + this.props.timeRangeEnd;
    }

    try {
      const data = await fetch(url);
      data.forEach(TrafficSign => {
        TrafficSign.construction_site = this.props.selectedConstructionSite.get('name');
        TrafficSign.latitude = Math.round(TrafficSign.latitude * 10000) / 10000;
        TrafficSign.longitude = Math.round(TrafficSign.longitude * 10000) / 10000;
        TrafficSign.trafficSigns = '';
        TrafficSign.signs.forEach(sign => {
          TrafficSign.trafficSigns += sign.name + ', ';
        });
        TrafficSign.trafficSigns = TrafficSign.trafficSigns.slice(0, -2);
      });
      signs = data;
    } catch (error) {
      this.props.showMessage('Virhe', 'Palvelin virhe', 'Error');
    }

    return signs;
  }

  async customExcelTrafficSign () {
    if (this.props.selectedConstructionSite == null) {
      this.props.showNotice('Valitse ensin haluttu kohde', 'Warning');
      return;
    }

    const signs = await this.getTrafficSigns();

    if (signs.length === 0) {
      this.props.showNotice('Liikennemerkkejä ei ole yhtään', 'Warning');
      return;
    }

    this.setState({
      customName: this.state.constructionSiteName + ' - Raportti',
      customHeaders: ['Kohde', 'Tienumero', 'Tieosa', 'Paalu', 'Latitude', 'Longitude', 'Merkki ylös',
        'Merkki pois', 'Merkit'],
      customDataHeaders: ['construction_site', 'road_number', 'road_part', 'road_distance', 'latitude',
        'longitude', 'erected', 'raised', 'trafficSigns'],
      customTimeField: ['erected', 'raised'],
      customData: fromJS(signs),
      showCustomExcel: true
    });
  }

  render () {
    if (this.state.choices != null) {
      return (
        <div>
          <ChooseType choices={this.state.choices} back={this.clearApplicationChoices} />
          <Excel show={this.state.showCustomExcel}
            toggle={this.toggleCustomExcel}
            name={this.state.customName}
            headers={this.state.customHeaders}
            dataHeaders={this.state.customDataHeaders}
            timeField={this.state.customTimeField}
            data={this.state.customData} />
        </div>
      );
    }

    return (
      <StartView show={this.state.showStartView} buttons={this.state.applications}
        setApplicationChoices={this.setApplicationChoices} />
    );
  }
}

export default connect(state => ({
  permissions: state.login.get('user') ? state.login.get('user').get('permissions')
    : [],
  contracts: state.contractSelect.get('contracts'),
  selectedContract: state.contractSelect.get('selectedContract'),
  selectedConstructionSite: state.constructionSiteSelect.get('selectedConstructionSite'),
  timeRangeStart: state.timeRange.get('startTime'),
  timeRangeEnd: state.timeRange.get('endTime'),
}), { showMessage, showNotice })(Report);
