import React from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { showNotice, showMessage, addContract } from 'components';
import { fetch } from '../utils';
import Form from "@rjsf/core";

const schema = {
  type: "object",
  required: ["name"],
  properties: {
    name: { type: "string", title: "Nimi:" },
    year: { type: "integer", title: "Kausi:" },
    orderer: { type: "string", title: "Tilaaja:" }
  }
};

const uiSchema = {
  name: {
    "ui:autofocus": true
  },
}

class ContractsNew extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      redirect: false
    };
  }

  onSubmit = ({ formData }) => {
    const data = {
      name: formData['name'],
      year: formData['year'],
      orderer: formData['orderer'],
      organization_id: this.props.organizationId
    };
    fetch('/contracts/', 'POST', data).then(data => {
      this.props.showNotice('Urakka lisätty', 'Ok');
      this.props.addContract(data);
      this.setState({
        redirect: true
      });
    }).catch(error => {
      if (error.message === '400') {
        this.props.showMessage('Virhe', 'Palvelin ei hyväksynyt annettua tietoa (Ehkä urakka on jo olemassa)', 'Warning');
      }
      else {
        this.props.showMessage('Virhe', 'Palvelimeen ei saatu yhteyttä', 'Error');
      }
    });
  }

  render () {
    if (this.state.redirect) return <Navigate to='/contracts' push />;
    return (
      <div className="main-client-container">
        <h1>Lisää urakka</h1>
        <Form schema={schema}
          uiSchema={uiSchema}
          onSubmit={this.onSubmit}
          showErrorList={false}>
          <div>
            <button className="button-primary" type="submit">Lisää urakka</button>
            <Link to="/contracts">
              <button className="button-secondary">Peruuta</button>
            </Link>
          </div>
        </Form>
      </div>
    );
  }
}

export default connect(state => ({
  organizationId: state.login.get('user') == null ? null : state.login.get('user').get('organizationId'),
}), { showNotice, showMessage, addContract })(ContractsNew);
