import React from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { showNotice, showMessage } from 'components';
import { fetch, stateValueParser } from '../utils';


class TrucksEdit extends React.Component {

  constructor(props) {
    super(props);

    this.getTruck(parseInt(this.props.params.id, 10));

    this.state = {
      redirect: false,
      editTruck: null
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  changeState(propertyName, type, defaultValue, event) {
    const value = stateValueParser(event, type, defaultValue);

    if (value == null) {
      return;
    }

    this.setState({[propertyName]: value});
  }

  getTruck(id) {
    fetch('/trucks/' + id + '/').then(data => {
      this.setState({
        truck: data,
        registerNumber: data['register_number'],
        defaultMass: data['default_mass'],
      });
    }).catch(error => {
      this.props.showNotice('Rekkaa ei löytynyt', 'Warning');
      this.setState({
        redirect: true,
      });
    });
  }

  onSubmit() {
    const mass = parseFloat(this.state.defaultMass);

    if (isNaN(mass) || mass === 0) {
      this.props.showNotice('Massa määrä on virheellinen', 'Warning');
      return;
    }

    const data = {
      register_number: this.state.registerNumber.toUpperCase(),
      default_mass: mass,
      organization_id: this.props.organizationId
    };

    fetch('/trucks/' + this.state.truck.id + '/', 'PATCH', data).then(data => {
      this.props.showNotice('Rekka päivitetty', 'Ok');
      this.setState({
        redirect: true
      });
    }).catch(error => {
      this.props.showMessage('Virhe', 'Palvelin virhe', 'Error');
    });
  }

  render() {
    if (this.state.redirect) return <Navigate to='/trucks' push/>;
    if (this.state.truck == null) {
      return (
        <div className="main-client-container center">
          <h2>Ladataan tietoja...</h2>
          <div className="loader"></div>
        </div>
      );
    }
    return (
    <div className="main-client-container">
      <h1>Muokkaa rekkaa</h1>
      <h2>{this.state.truck.register_number}</h2>
      <div className='row'>
        <div className='column'>
          <label htmlFor='registerNumber'>Rekisterinumero:</label>
          <input id='registerNumber' type='text'
               value={this.state.registerNumber}
               onChange={this.changeState.bind(this, 'registerNumber', 'string', '')} required/>
        </div>
        <div className='column'>
          <label htmlFor='defaultMass'>Vakio massa Määrä:</label>
          <input id='defaultMass' type='tel'
                value={this.state.defaultMass}
                onChange={this.changeState.bind(this, 'defaultMass', 'float', 0.0)} required/>
        </div>
      </div>
        <div>
          <button className="button-primary" onClick={this.onSubmit}>Tallenna</button>
          <Link to="/trucks">
            <button className="button-outline">Peruuta</button>
          </Link>
        </div>
    </div>
    );
  }
}


export default connect(state => ({
  organizationId: state.login.get('user') ? state.login.get('user').get('organizationId') : null
}), { showNotice, showMessage })(TrucksEdit);
